<template>
  <VueContentHolder id="divProfitWrapper">
    <BrandResultView
      @handleSubmit="submitResultPopUp"
      @close="closeModal"
      :key="showResultView"
      :showComponent="showResultView"
      :topTitle="resultViewItems.topTitle"
      :bottomTitle="resultViewItems.bottomTitle"
      :topValue="resultViewItems.topValue"
      :bottomValue="resultViewItems.bottomValue"
    ></BrandResultView>

    <Header :text="headerContent[0].text" :headline="headerContent[0].headline"></Header>
    <VueSpacer level="20" />
    <VueForm :bodyPadding="['0', '20']" ref="form">
      <VueInput
        name="numberOfVisits"
        id="numberOfVisits"
        type="text"
        placeholder="PM satış ekibi sizi haftada kaç kere ziyaret ediyor?"
        v-model="formModel.numberOfVisits"
        :messageOnTop="true"
        validationRules="required|numeric"
        validatorName="Ziyaret sayısı"
      ></VueInput>
      <VueSpacer level="20" />
      <VueInput
        name="averageCostPerVisit"
        id="averageCostPerVisit"
        type="text"
        placeholder="Her ziyarette ortalama fatura tutarınız nedir?"
        v-model="formModel.averageCostPerVisit"
        :messageOnTop="true"
        validationRules="required|numeric"
        validatorName="Tutar"
      ></VueInput>
      <VueSpacer level="20" />
    </VueForm>
    <VueSpacer level="60" />

    <BrandButton
      v-if="!showResultView"
      :size="sizes.xxLarge"
      :contentAlignment="constants.flexAlignment.center"
      type="submit"
      :padding="{ vertical: 15, horizontal: 0 }"
      @click.prevent="handleSubmit"
      >HESAPLA</BrandButton
    >
  </VueContentHolder>
</template>

<script>
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import Header from '@/components/brand/Headers/Header.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import BrandResultView from '@/components/brand/BrandResultView/BrandResultView.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import { Learn } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils';

export default {
  name: 'Endorsement',
  mixins: [gtmUtils],
  components: {
    Header,
    VueForm,
    VueInput,
    BrandButton,
    VueContentHolder,
    VueSpacer,
    BrandResultView,
  },
  data() {
    return {
      resultViewItems: {
        topTitle: 'Elde Ettiğiniz Toplam Ciro (TL)',
        topValue: 0,
        bottomTitle: 'Elde Ettiğiniz Toplam Kâr (TL)',
        bottomValue: 0,
      },
      formModel: {
        numberOfVisits: '',
        averageCostPerVisit: '',
      },
      headerContent: [
        {
          headline: 'Kârınızı hesaplayınız:',
          text: 'Philip Morris ile çalışmak size kazandırır!',
        },
      ],
      showResultView: false,
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },
  created() {
    this.setBottomBarStatus(false);
  },
  destroyed() {
    this.setBottomBarStatus(true);
  },
  methods: {
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    async handleSubmit() {
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        Learn.getAnnualProfit(
          this.formModel.numberOfVisits,
          this.formModel.averageCostPerVisit,
        ).then(res => {
          let response = res.data.Data;
          if (response) {
            this.resultViewItems.topValue = response.annualRevenue;
            this.resultViewItems.bottomValue = response.annualProfit;
            this.showResultView = true;
            this.pushDataLayerEvent('learn', {
              event: 'profit_calculation',
              pm_weekly_visit: this.formModel.numberOfVisits,
              pm_avg_amount: this.formModel.averageCostPerVisit,
              yearly_revenue: response.annualRevenue,
              yearly_profit: response.annualProfit,
            });
          }
        });
      }
      if (this.showResultView) {
        document.documentElement.style.overflow = 'hidden';
      }
    },
    closeModal() {
      this.showResultView = false;
      if (!this.showResultView) {
        document.documentElement.style.overflow = 'auto';
      }
    },
    submitResultPopUp() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.ManageMyBusiness.path}`);
    },
  },
};
</script>

<style scoped lang="scss"></style>
